import {
  FC,
  useState,
  useMemo,
  useCallback,
  useEffect,
  ReactNode,
} from 'react';
import { useRouter } from 'next/router';
import {
  Headline,
  Card,
  IconButton,
  Body,
  useModal,
  spacing,
} from '@sumup/circuit-ui';
import { Shop } from '@sumup/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useAuth } from '@sumup/react-nanoauth';

import { OrderOverviewContainer } from '../OrderOverview';

import { Grid } from 'shared/components/Grid';
import { ICartContent } from 'shared/types/cart';
import { IOrderDetails } from 'shared/store/order/types';
import { IProduct } from 'productSelection/types/products';
import { getShopExperienceLink } from 'shared/utils/shop-experience-link';
import { getOrderTotalAmountFormatted } from 'utils/tax';
import { getFormattedInstallmentAmount } from 'productSelection/services/product';
import { redirectToSSOSignup } from 'shared/sso/RedirectService';
import { dispatchGoToCheckoutEvent } from 'shared/services/tracker/events';
import { useShopExperience } from 'shared/context';
import { getOrderIFrameUrl } from 'src/experiments/skip-shop/create-order/pages';
import { useLineItems } from 'checkout/hooks/useLineItems';
import { useTypedSelector } from 'shared/store';
import { Button } from 'src/experiments/odl/components/Button';

const NOTIFICATION_SIZE = 24;
const NOTIFICATION_OFFSET = 8;
export const CART_CONTAINER_HEIGHT = '74px';

const StyledButton = styled(Button)(
  ({ theme }) => css`
    padding: 8px 10px;
    ${theme.mq.kilo} {
      padding: 8px calc(24px - 1px);
      font-size: 16px;
    }
  `,
);

// calculate a z-index over that of the modal
// so the CTA remains visible and operable
const CartWrapper = styled(Card)(
  ({ theme }) => css`
    width: 100%;
    display: block;
    background-color: var(--cui-bg-normal);
    padding: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    border-radius: 0;
    z-index: ${theme.zIndex.modal + 1};
  `,
);

const CartContainer: FC<{ children: ReactNode }> = styled(Grid)(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--cui-bg-normal);
    padding: ${theme.spacings.mega};
    ${theme.mq.untilKilo} {
      padding: ${theme.spacings.mega};
      justify-content: space-between;
    }
  `,
);

const StyledModalWrapper = styled('div')(
  ({ theme }) => css`
    padding-bottom: calc(${theme.spacings.mega} + ${CART_CONTAINER_HEIGHT});
    border-radius: ${theme.borderRadius.byte} ${theme.borderRadius.byte} 0px 0px;
    ${theme.mq.kilo} {
      margin: 0 auto;
      padding-bottom: ${theme.spacings.mega};
      border-radius: ${theme.borderRadius.byte};
    }
  `,
);

const PriceContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: row;
    ${theme.mq.kilo} {
      margin-right: ${theme.spacings.mega};
    }
  `,
);

const RoundedIconButton = styled(IconButton)(
  ({ theme }) => css`
    border-radius: 50%;
    margin-right: ${theme.spacings.kilo};
  `,
);

const NotificationIconButton = styled(RoundedIconButton)<{ quantity?: number }>(
  ({ quantity }) => css`
    position: relative;
    overflow: visible;
    ${quantity &&
    `
      ::before {
      content: '${quantity}';
      color: var(--cui-fg-on-strong);
      width: ${NOTIFICATION_SIZE}px;
      height: ${NOTIFICATION_SIZE}px;
      background-color: var(--cui-bg-danger-strong);
      position: absolute;
      border-radius: 50%;
      top: -${NOTIFICATION_OFFSET}px;
      right: -${NOTIFICATION_OFFSET}px;
    }
    `}
  `,
);

const StyledShop = styled(Shop)(css`
  width: 16px;
  height: 16px;
`);

export interface CartProps extends ICartContent, Partial<IOrderDetails> {
  products?: IProduct[];
  productsContentful: IProduct[];
  isHidden?: boolean;
  defaultTaxRate: number;
}

/**
 * The cart component describes the shared collapsed cart
 * used to track on cart products during the whole purchase journey.
 */
export const Cart: FC<CartProps> = (props) => {
  const {
    totalAmountFloat,
    formattedTotalAmountWithTaxes,
    products,
    largestInstallmentInCart,
    primaryButtonText,
    overviewTitleText,
    isHidden = false,
    defaultTaxRate = 0,
  } = props;

  const { authRedirect, authState } = useAuth();
  const router = useRouter();
  const { locale, push, query } = router;
  const source = useShopExperience();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setModal, removeModal } = useModal();
  const isCartHidden = isHidden || products.length < 1;
  const isCartDisabled = isCartHidden || isModalOpen;
  const quantityInCart = useMemo(
    () => products.reduce((acc, product) => acc + product.quantity, 0),
    [products],
  );
  const hasInstallments = largestInstallmentInCart > 1;
  const formattedInstallmentAmount = getFormattedInstallmentAmount(
    totalAmountFloat,
    largestInstallmentInCart,
    locale,
  );

  const { orderDetails } = useTypedSelector((state) => state.order);
  const shippingAddress = useTypedSelector(
    (state) => state.addresses.shippingAddress,
  );

  const onGoToCheckoutClick = useCallback(() => {
    removeModal();
    dispatchGoToCheckoutEvent({
      shopExperience: source,
    });
    const requestParams = {
      ...{
        iframe_uri: getOrderIFrameUrl(orderDetails.id, locale),
        iframe_title: overviewTitleText,
      },
    };

    redirectToSSOSignup({
      locale,
      authRedirect,
      authState,
      push,
      query,
      redirectPathname: getShopExperienceLink(`/checkout/address`),
      source,
      requestParams,
    });
  }, [
    authRedirect,
    authState,
    locale,
    push,
    query,
    removeModal,
    source,
    orderDetails.id,
    overviewTitleText,
  ]);

  const { changeLineItemQuantityDebounced } = useLineItems();

  const onLineItemQuantityChange = useCallback(
    (product: IProduct) =>
      async (quantity: number): Promise<void> => {
        await changeLineItemQuantityDebounced(product, quantity);
      },
    [changeLineItemQuantityDebounced],
  );

  const Modal = useMemo(
    () => (
      <StyledModalWrapper data-testid="order-overview-modal">
        <Headline size="four" as="h2" css={spacing({ bottom: 'mega' })}>
          {props.overviewTitleText}
        </Headline>
        <OrderOverviewContainer
          {...props}
          onLineItemQuantityChange={onLineItemQuantityChange}
          overviewTitleText={null}
          usedInModal
          usedForCart
          canEmptyCart
        />
      </StyledModalWrapper>
    ),
    [props, onLineItemQuantityChange],
  );

  const openCartModal = useCallback(() => {
    setIsModalOpen(true);
    setModal({
      onClose: () => setIsModalOpen(false),
      children: Modal,
      closeButtonLabel: primaryButtonText,
    });
  }, [Modal, primaryButtonText, setModal]);

  const totalAmountFormatted = getOrderTotalAmountFormatted({
    shippingAddress,
    defaultTaxRate,
    totalAmountFloat,
    formattedTotalAmountWithTaxes,
    locale,
    doFallbackToDefaultTaxRate: true,
  });

  // automatically close the modal if the cart is empty
  useEffect(() => {
    if (isCartHidden) {
      removeModal();
    }
  }, [isCartHidden, removeModal]);

  if (isCartHidden) {
    return null;
  }

  return (
    <CartWrapper data-testid="shopping-cart">
      <CartContainer>
        <PriceContainer>
          <NotificationIconButton
            data-testid="open-modal-button"
            label="shopping-cart"
            onClick={openCartModal}
            quantity={quantityInCart}
            disabled={isCartDisabled}
          >
            <StyledShop />
          </NotificationIconButton>
          {hasInstallments ? (
            <>
              <Body size="two" variant="subtle" css={spacing({ right: 'bit' })}>
                {largestInstallmentInCart}x
              </Body>
              <Body variant="highlight">{formattedInstallmentAmount}</Body>
            </>
          ) : (
            <Body variant="highlight">{totalAmountFormatted}</Body>
          )}
        </PriceContainer>

        <StyledButton
          variant="primary"
          onClick={onGoToCheckoutClick}
          disabled={isCartHidden}
          data-testid="go-to-checkout-btn"
        >
          {primaryButtonText}
        </StyledButton>
      </CartContainer>
    </CartWrapper>
  );
};
